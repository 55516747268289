export const UI_URLS = {
  HOME: "/",
  ACTIVITY: "/activity",
  ACCOUNTS: "/accounts",
  CONTROL: "/control",
  CONTROLS: "/controls",
  DEVELOPERS: "/developers",
  DASHBOARD: "/dashboard",
  DIRECTORIES: "/directories",
  DIRECTORY: "/directory/:id/:type?",
  ORGANIZATION: "/organization",
  GUARDRAILS: "/guardrails",
  HELP: "/help",
  LOGIN: "/login",
  LOGOUT: "/logout",
  MOD: "/mod",
  MODS: "/mods",
  NOTIFICATIONS: "/notifications",
  NEWS: "/news",
  OVERVIEW: "/overview",
  PERMISSION: "/permission",
  PERMISSIONS: "/permissions",
  POLICIES: "/policies",
  POLICIES_VALUES: "/policies/values",
  POLICIES_SETTINGS: "/policies/settings",
  POLICY: "/policy",
  PROCESSES: "/processes",
  PROFILE: "/profile",
  PROFILE_ACCOUNT: "/profile/account",
  PROFILE_KEYS: "/profile/keys",
  PROFILE_ACCESS_KEYS: "/profile/access-keys",
  PROFILE_SSH_KEYS: "/profile/ssh-keys",
  REPORTS: "/reports",
  RESOURCE: "/resource",
  RESOURCES: "/resources",
  // FIXME: can be removed after fully migrate to smart folders
  RESOURCE_GROUPS: "/resource-groups",
  SMART_FOLDERS: "/smart-folders",
  SMART_FOLDER: "/smart-folder",
  POLICY_PACKS: "/policy-packs",
  POLICY_PACK: "/policy-pack",
  SEARCH: "/search",
  TASKS: "/tasks",
};
